<template>
  <v-container fill-height>
    <v-row align-content="center">
      <v-col align-self="center">
        <v-card
          elevation="10"
          class="mx-auto my-12"
          max-width="450"
          :loading="loading"
        >
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Login"
                required
                prepend-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                v-model="password"
                type="password"
                :rules="[(v) => !!v || 'Password is required']"
                label="password"
                required
                prepend-icon="mdi-key"
              ></v-text-field>

              <v-btn class="mr-4" @click="submit">
                submit
              </v-btn>
              <v-btn @click="clear">
                clear
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :timeout="snackbar_timeout"
          top
          color="red lighten-2"
        >
          {{ snackbar_text }}
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { onLogin } from "../vue-apollo.js";
import LOGIN from "../graphql/LOGIN.gql";

export default {
  data: () => ({
    valid: true,
    snackbar: false,
    snackbar_text: "",
    snackbar_timeout: 5000,
    email: "ghadriah@yahoo.com",
    password: "Abdelkader",
    loading: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  computed: {},

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: LOGIN,
            variables: {
              input: { username: this.email, password: this.password },
            },
          })
          .then((data) => {
            //this.snackbar = true;
            onLogin(
              this.$apollo.provider.defaultClient,
              data.data.login.access_token
            );
            this.loading = false;

            this.$router.push("/listedevis");
          })
          .catch((error) => {
            //console.log(error.message);
            this.loading = false;
            this.snackbar_text = error;
            this.snackbar = true;
          });
      }
    },

    clear() {
      this.password = "";
      this.email = "";
    },
  },
};
</script>

<style></style>
